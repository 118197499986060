import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Typography, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const API_PATH = process.env.REACT_APP_API_PATH;

const AppBarWithHamburger = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if(event.target.innerText === '资料列表') {
        navigate('/index', { replace: true });
      }

      if(event.target.innerText === '登出') {
        localStorage.clear();
        navigate('/login', { replace: true });
      }
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = ['资料列表','登出'];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor:'black' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2}}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <Typography variant="h5" gutterBottom sx={{marginLeft:5,marginTop:2}}>
            {localStorage.getItem('name')}
        </Typography>
        <List sx={{ width: 250 }}>
          {menuItems.map((text) => (
            <ListItem button key={text} onClick={toggleDrawer(false)}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default AppBarWithHamburger;