import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import BatteryProgress from './BatteryProgress';
import AppBarWithHamburger from './AppBarWithHamburger';
import BackgroundAnimate from './BackgroundAnimate';
import axios from 'axios';
import { Container, Box, CircularProgress } from '@mui/material';

const API_PATH = process.env.REACT_APP_API_PATH;

const Member = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [option1, setOption1] = useState(0);
  const [option2, setOption2] = useState(0);
  const [option3,setOption3] = useState(0);
  const [customerMemo, setOption4CustomerMemo] = useState('');
  const [option5,setOption5] = useState(0);

  const [option1Id, setOption1Id] = useState(0);
  const [option2Id, setOption2Id] = useState(0);
  const [option3Id,setOption3Id] = useState(0);
  const [customerMemoId, setOption4CustomerMemoId] = useState(0);
  const [option5Id,setOption5Id] = useState(0);

  const [option1point, setOption1point] = useState(0);
  const [option2point, setOption2point] = useState(0);
  const [option3point,setOption3point] = useState(0);
  const [customerMemoPoint, setOption4CustomerMemoPoint] = useState(0);
  const [option5point,setOption5point] = useState(0);

  const [option1s, setOption1s] = useState([]);
  const [option2s, setOption2s] = useState([]);
  const [option3s,setOption3s] = useState([]);
  const [option4s,setOption4s] = useState([]);
  const [option5s,setOption5s] = useState([]);
  const [amount, setAmount] = useState(0);
  const [point, setPoint] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const navigate = useNavigate();

  const handleDataSubmit = async () => {
    if (!selectedFile) {
      alert("请先选择一个文件！");
      return;
    }

    if(option1Id === 0 || option2Id === 0 || customerMemoId === 0 || option3Id === 0 || option5Id === 0 || amount === 0) {
      alert("请先选择完成所有选项 OR 数量不得为0");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("option1", option1);
    formData.append("option3", option3);
    formData.append("option5", option5);
    formData.append("option2", option2);
    formData.append("amount", amount);
    formData.append("customerMemo", customerMemo);
    formData.append("point", point);

    try {
      const response = await axios.post(`${API_PATH}/customer/data`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("上传成功:", response.data);
      alert("文件上传成功！");
      fetchData();
      setOpen(false);
    } catch (error) {
      console.error("上传失败:", error);
      alert("文件上传失败！");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleOption1Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption1Id(selectedId);
    }else {
      const selectedOption = option1s.find(o1 => o1.id === selectedId);
      setOption1(selectedOption.viewValue);
      setOption1Id(selectedId);
      setOption1point(selectedOption.realValue);
    }
  };

  const handleOption2Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption2Id(selectedId);
    }else {
      const selectedOption = option2s.find(o2 => o2.id === selectedId);
      setOption2(selectedOption.viewValue);
      setOption2point(selectedOption.realValue);
      setOption2Id(selectedId);
    }
  };

  const handleOption3Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption3Id(selectedId);
    }else {
      const selectedOption = option3s.find(o3 => o3.id === selectedId);
      setOption3(selectedOption.viewValue);
      setOption3point(selectedOption.realValue);
      setOption3Id(selectedId);
    }
  };

  const handleOption4CustomerMemoChange = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption4CustomerMemoId(selectedId);
    }else {
      const selectedOption = option4s.find(o4 => o4.id === selectedId);
      setOption4CustomerMemoPoint(selectedOption.realValue);
      setOption4CustomerMemo(selectedOption.viewValue);
      setOption4CustomerMemoId(selectedId);
    }
  };

  const handleOption5Change = (event) => {
    const selectedId = event.target.value;
    if(selectedId === 0) {
      setOption5Id(selectedId);
    }else {
      const selectedOption = option5s.find(o5 => o5.id === selectedId);
      setOption5(selectedOption.viewValue);
      setOption5point(selectedOption.realValue);
      setOption5Id(selectedId);
    }
  };


  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUserAndOptionsData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/customer/information`);
      if (response.status === 200) { 
        setOption1s(response.data.source.option1s);     
        setOption2s(response.data.source.option2s);   
        setOption3s(response.data.source.option3s);   
        setOption4s(response.data.source.option4s);   
        setOption5s(response.data.source.option5s);   
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/customer/logs`);
      
      if (response.status === 200) { 
        setRows(response.data.source);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };
  
  useEffect(() => {
    // 使用Axios从后端获取数据
    fetchData();
    fetchUserAndOptionsData();
  }, []);

  useEffect(() => {
    if(option1Id !== 0 && option2Id !== 0 && customerMemoId !== 0 && option3Id !== 0 && option5Id !== 0) {
      const total = option1point+option2point+option3point+customerMemoPoint+option5point;
      setPoint(total * amount);
    }
  }, [option1,option2,option3,customerMemo,option5,amount]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'option1', headerName: '选项1', width: 100 },
    { field: 'option2', headerName: '选项2', width: 80 },
    { field: 'option3', headerName: '选项3', width: 120 },
    { field: 'customerMemo', headerName: '你的备注/选项4', width: 200 },
    { field: 'option5', headerName: '选项5其他', width: 150 },
    {
      field: 'pipeline',
      headerName: '进度条',
      width: 150,
      renderCell: (params) => <BatteryProgress value={params.value} />,
    },
    { field: 'point', headerName: '点数', width: 100 },
    { field: 'createDate', headerName: '提交时间', width: 120 },
    { field: 'status', headerName: '状态', width: 80 },
    { field: 'memo', headerName: '店家备注', width: 100 },
    { field: 'uploadFileUrl', headerName: '上传文件', width: 100 },
    { field: 'downloadFileUrl', headerName: '下载文件', width: 100 },
];


  return (
    <>
        <BackgroundAnimate />
        <AppBarWithHamburger/>    
        <Box sx={{ height: 800, width: '90%',margin:'auto', backgroundColor:'white',paddingTop:5,marginBottom:50,boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
        borderRadius: '8px', }} >
            <Typography variant="h3" gutterBottom style={{display:'flex',justifyContent:'center',marginTop:10}}>
                资料列表
            </Typography>
            <Button variant="contained" endIcon={<SendIcon />} style={{marginBottom:20,marginLeft:20}} onClick={handleClickOpen}>
                上传
            </Button>
            {loading ? (
            <CircularProgress />
            ) : (
            <DataGrid rows={rows} columns={columns} pageSize={50} rowsPerPageOptions={[5]} style={{backgroundColor:'white'}}/>
            )}
        </Box>
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>新增资料</DialogTitle>
                <DialogContent>
                {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We
                    will send updates occasionally.
                </DialogContentText> */}
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">选项1</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option1Id}
                            onChange={handleOption1Change}
                            label="选项1"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option1s.map((o1) => (
                            <MenuItem key={o1.id} value={o1.id}>
                                {o1.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">选项2</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option2Id}
                            onChange={handleOption2Change}
                            label="选项2"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option2s.map((o2) => (
                            <MenuItem key={o2.id} value={o2.id}> 
                                {o2.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">选项3</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option3Id}
                            onChange={handleOption3Change}
                            label="选项3"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option3s.map((o3) => (
                            <MenuItem key={o3.id} value={o3.id}>
                                {o3.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">选项4 备注</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={customerMemoId}
                            onChange={handleOption4CustomerMemoChange}
                            label="选项4"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option4s.map((o4) => (
                            <MenuItem key={o4.id} value={o4.id}>
                                {o4.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" fullWidth style={{marginTop:5}}>
                        <InputLabel id="country-select-label">选项5</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={option5Id}
                            onChange={handleOption5Change}
                            label="选项5"
                        >
                            <MenuItem key={0} value={0}>
                              请选择
                            </MenuItem>
                            {option5s.map((o5) => (
                            <MenuItem key={o5.id} value={o5.id} >
                                {o5.viewValue}
                            </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="数量"
                        fullWidth
                        variant="standard"
                        value={amount}
                        onChange={handleAmountChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="点数"
                        fullWidth
                        variant="standard"
                        value={point}
                    />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                    <Typography variant="h6" component="div" gutterBottom>
                        文件上传
                    </Typography>
                    <Input
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document' }} // 只接受特定文件类型
                    />
                    {selectedFile && (
                        <Typography variant="body1" component="div" sx={{ mt: 2 }}>
                        已选择文件: {selectedFile.name}
                        </Typography>
                    )}
                    </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="button" onClick={handleDataSubmit}>Upload</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

    </>
  );
};

export default Member;
