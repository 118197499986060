import React from 'react';
import { MatrixRainingLetters } from "react-mdr";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container } from '@mui/material';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
  } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
const API_PATH = process.env.REACT_APP_API_PATH;


function LoginForm() {
    const [acc,setAcc] = useState('test123999');
    const [pw,setPw] = useState('test@123456');
    const [open, setOpen] = React.useState(false);

    const data = [
        { amount: '588USDT', fee: '30USDT', expiration: '2024-12-31 23:59:59' },
        { amount: '1088USDT', fee: '60USDT', expiration: '2024-12-31 23:59:59' },
        { amount: '2088USDT', fee: '150USDT', expiration: '2024-12-31 23:59:59' },
        { amount: '4088USDT', fee: '350USDT', expiration: '2024-12-31 23:59:59' },
        { amount: '8088USDT', fee: '750USDT', expiration: '2024-12-31 23:59:59' },
    ];

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const parseJwt = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
        return JSON.parse(jsonPayload);
    } catch (e) {
        return null;
    }
    };
    const navigate = useNavigate();
    const handleLogin = async () => {
        try {
      
            const response = await axios.post(`${API_PATH}/customer/login`, { Account: acc, Password: pw });  
            console.log(response)
            if (response.status === 200) {
              localStorage.setItem('token', response.data.source); 
              const decodedToken = parseJwt(response.data.source);
              console.log(decodedToken)
              localStorage.setItem('name',  decodedToken.Name); 
              localStorage.setItem('id',  decodedToken.Id); 
              navigate('/index');
            }
          } catch (error) {
              alert(error.response.data)
          }      
    };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
        <MatrixRainingLetters uniqueKey={uuidv4()} custom_class="m-0 p-0" />
        <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor:'white',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
            borderRadius: '15px', // 添加圆角
            padding: '20px', // 添加一些内边距使内容不贴边
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // 添加一些阴影效果
            }}>
            <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                    marginTop: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                >
                <Typography component="h1" variant="h5" style={{fontWeight:'bolder'}}>
                    系统登入
                </Typography>
                
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="account"
                    label="Account"
                    name="account"
                    autoComplete="account"
                    onChange={(e)=>setAcc(e.target.value)}
                    value={acc}
                    autoFocus
                    />
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    onChange={(e)=>setPw(e.target.value)}
                    value={pw}
                    id="password"
                    autoComplete="current-password"
                    />
                    <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 5,backgroundColor:'black' }}
                    onClick={handleLogin}
                    >
                    Sign In
                    </Button>
                    <Button
                        component="button"
                        variant="body2"
                        onClick={() => {
                            handleClickOpen();
                        }}
                        >                      
                        储值说明
                    </Button>
                </Box>
                </Box>
            </Container>
            </ThemeProvider>
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                    {"交易信息"}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        1. 请确保转账金额正确。
                        <br />
                        2. 请在 60 分钟内完成转账。转账时请再次确认地址和金额是否跟页面一致！
                        <br />
                        3. 交易成功后会自动充值到账。
                        <br />
                        4. 如因转账金额错误导致的充值失败，请及时联系客服将处理！
                    </DialogContentText>

                    {/* 添加表格 */}
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>充值数量</TableCell>
                            <TableCell>赠送数量</TableCell>
                            <TableCell>截止日期</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.amount}</TableCell>
                                <TableCell>{row.fee}</TableCell>
                                <TableCell>{row.expiration}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <img
                        src={process.env.PUBLIC_URL + '/Wallet.jpg'}
                        alt="QR Code"
                        style={{ width: '300px', height: '300px' }} // 设置二维码的尺寸
                        />
                    </Box>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        同意
                    </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    </div>
  );
}

export default LoginForm;
